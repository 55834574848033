<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      :empty-message="emptyMessage"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'three-line': true }"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :page-loaded="pageLoaded"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      pk-name="subsis.idsubsis"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
    >
      <template
        #listItem="slotProps"
      >
        <lparte-trabajo-material-afectado-subsis-list-item
          v-show="verVacios || !!slotProps.item.cantidadMateriales"
          :item="slotProps.item"
        />
      </template>
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
        :idchecklist-ot="idchecklistOt"
      />
    </b10-list>
    <lparte-trabajo-material-afectado-subsis-list-legend
      :showing.sync="showingDialogs.legends"
    />
    <b10-fab-button
      v-if="estadoPartePendiente"
      :icon="$vuetify.icons.values.search"
      @click="clickBuscarMaterial"
    />
    <b10-fab-button
      left
      :icon="iconVerVacios"
      @click="clickVerVacios"
    />
    <buscar-material-afectado
      :showing.sync="showingDialogs.buscarMaterialAfectado"
      :idparte-trabajo="routeParams.idparte_trabajo"
      @click-buscar-avanzado="clickBuscarMaterialAvanzado"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './LparteTrabajoMaterialAfectadoSubsisListData'
import LparteTrabajoMaterialAfectadoSubsisListItem from './components/LparteTrabajoMaterialAfectadoSubsisListItem'
import LparteTrabajoMaterialAfectadoSubsisListLegend from './components/LparteTrabajoMaterialAfectadoSubsisListLegend'
import { get, sync } from 'vuex-pathify'
import { NextRoute } from '@/router'
import { PARTE_TRABAJO } from '@/utils/consts'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'
import BuscarMaterialAfectado from '../components/BuscarMaterialAfectado'
import _ from '@/utils/lodash'

const pageStoreName = 'pagesOfflineLparteTrabajoMaterialAfectadoSubsisList'

export default {
  components: {
    LparteTrabajoMaterialAfectadoSubsisListItem,
    LparteTrabajoMaterialAfectadoSubsisListLegend,
    VolverParteChecklistMenuItems,
    BuscarMaterialAfectado,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        addMaterialNuevo: {
          title: 'Material nuevo',
          visible: true,
          icon: 'plus'
        },
        addMaterialNuevoPlantilla: {
          title: 'Material nuevo (plantillas)',
          visible: true,
          icon: 'plus'
        },
        addMaterialSistema: {
          title: 'Material desde el sistema',
          visible: true,
          icon: 'plus'
        },
        addAccion: {
          title: 'Acción al material',
          visible: true,
          icon: 'add'
        },
        marcarAccionRealizada: {
          title: 'Marcar acciones realizadas',
          visible: true,
          icon: 'checkAll'
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        legends: false,
        buscarMaterialAfectado: false,
      },
      parteTrabajo: {},
      emptyMessage: '',
      idsubsis: [],
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    verVacios: sync(`${pageStoreName}/verVacios`),
    estadoPartePendiente () {
      return (
        this.parteTrabajo &&
        this.parteTrabajo.parte_trabajo &&
        this.parteTrabajo.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente
      )
    },
    idchecklistOt () {
      if (this.parteTrabajo && this.parteTrabajo.checklist_ot && this.parteTrabajo.checklist_ot.idchecklist_ot) {
        return this.parteTrabajo.checklist_ot.idchecklist_ot
      } else {
        return null
      }
    },
    iconVerVacios () {
      if (this.verVacios) {
        return this.$vuetify.icons.values.hide
      } else {
        return this.$vuetify.icons.values.show
      }
    },
  },
  async created () {
    // routeQuery.retirado: filtro por material retirado
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      if (this.routeQuery.retirado) {
        this.title = `Material retirado parte Nº${this.routeParams.idparte_trabajo}`
        this.emptyMessage = 'No se encontraron materiales retirados'
      } else {
        this.title = `Material afectado parte Nº${this.routeParams.idparte_trabajo}`
        this.emptyMessage = 'No se encontraron materiales afectados'
        this.parteTrabajo = await Data.selectParteTrabajo(this, this.routeParams.idparte_trabajo)
        this.toolbarOptions.addMaterialNuevo.visible = this.estadoPartePendiente && !this.routeQuery.retirado
        this.toolbarOptions.addMaterialSistema.visible = this.estadoPartePendiente && !this.routeQuery.retirado
        this.toolbarOptions.addAccion.visible = this.estadoPartePendiente && !this.routeQuery.retirado
        this.toolbarOptions.marcarAccionRealizada.visible = (
          this.estadoPartePendiente &&
          !this.routeQuery.retirado &&
          this.hasViewPerm(this.permissions.partes.marcarAccionesTodoRealizado)
        )
        const hasInsertPermMaterialSistema = this.hasInsertPerm(this.permissions.materialSistema.id)
        this.toolbarOptions.addMaterialNuevo.visible = hasInsertPermMaterialSistema
        this.toolbarOptions.addMaterialNuevoPlantilla.visible = hasInsertPermMaterialSistema
      }
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectMaterialAfectado(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idparte_trabajo,
          this.routeQuery.retirado
        )
        await this.loadItemsBase(dataset, metadata)
        // esta variable la uso luego para pasarsela al filtro de añadir material de sistema.
        this.idsubsis = _.map(dataset, 'subsis.idsubsis')
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectMaterialAfectadoRows(
            this, dirty.modified, this.routeParams.idparte_trabajo, this.routeQuery.retirado
          ),
          dirty,
          'subsis.idsubsis'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'offline__lparte-trabajo-material-afectado-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
        query: {
          idsubsis: item.data.subsis.idsubsis,
          retirado: this.routeQuery.retirado,
        },
      })
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.addMaterialNuevo) {
        this.$appRouter.push({
          name: 'offline__articulo-list',
          params: {
            nextRoute: NextRoute(
              'offline__lparte-trabajo-material-afectado-add',
              {
                idparte_trabajo: this.routeParams.idparte_trabajo,
                idarticulo: null,
              },
              {},
              true,
            ),
          },
          query: {
            idttarifa: this.parteTrabajo.orden_trabajo.idttarifa,
            idbanco_precio: this.parteTrabajo.orden_trabajo.idbanco_precio,
          }
        })
      } else if (option === this.toolbarOptions.addMaterialNuevoPlantilla) {
        this.$appRouter.push({
          name: 'offline__plantilla-articulo-list',
          params: {
            nextRoute: NextRoute(
              'offline__plantilla-articulo-aplicar',
              {
                idparte_trabajo: this.routeParams.idparte_trabajo,
                idplantilla_articulo: null,
              },
              {},
              true,
            ),
          },
        })
      } else if (option === this.toolbarOptions.addMaterialSistema) {
        this.$appRouter.push({
          name: 'offline__material-sistema-list',
          params: {
            idsistema: this.parteTrabajo.sistema.idsistema,
            nextRoute: NextRoute(
              'offline__lparte-trabajo-material-afectado-sistema-add',
              {
                idparte_trabajo: this.routeParams.idparte_trabajo,
                idmaterial_sistema: null,
              },
              {},
              true,
            ),
            _filter: {
              idsubsis: {
                value: this.idsubsis,
              },
            },
          },
          query: {
            idparte_trabajo_exclude: this.routeParams.idparte_trabajo,
          },
        })
      } else if (option === this.toolbarOptions.addAccion) {
        this.$appRouter.push({
          name: 'offline__accion-ejec-masiva-add',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
          query: {
            idchecklist_ot: this.idchecklistOt,
          },
        })
      } else if (option === this.toolbarOptions.marcarAccionRealizada) {
        this.$appRouter.push({
          name: 'offline__accion-ejec-masiva-realizada',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
          },
          query: {
            idchecklist_ot: this.idchecklistOt,
          },
        })
      }
    },
    clickBuscarMaterial () {
      this.showingDialogs.buscarMaterialAfectado = !this.showingDialogs.buscarMaterialAfectado
    },
    clickBuscarMaterialAvanzado () {
      this.showingDialogs.buscarMaterialAfectado = false
      this.$appRouter.push({
        name: 'offline__lparte-trabajo-material-afectado-list',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          _show_filter: true,
        },
      })
    },
    clickVerVacios () {
      this.verVacios = !this.verVacios
      if (this.verVacios) {
        this.$alert.showSnackbarInfo('Mostrando todos los subsistemas de la orden.')
      }
    },
  }
}
</script>
